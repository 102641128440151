<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">处理物料申领单</span>
      <div class="row">
        <div class="col-md-12">
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="form-group">
                <label><b>分类</b></label><br/>
                <el-input type="text" readonly v-model="stock.CAT_NAME" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>物品</b></label><br/>
                <el-input type="text" readonly v-model="stock.NAME" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>数量</b></label><br/>
                <el-input type="text" readonly v-model="model.BODY.CNT" />
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="form-group">
                <label><b>库存量</b></label>
                <div>
                  <el-input v-model="stock.BALANCE" readonly>
                    <template slot="prepend" ><i class="el-icon-coin"/></template>
                  </el-input>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>归属班级</b></label><br />
                <el-input type="text" readonly v-model="model.BODY.CLASS_NAME" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>备注</b></label>
                <el-input type="text" v-model="model.BODY.DESCRIPTION" readonly/>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="form-group">
                <label style="color: red"><b>处理意见</b></label>
                <el-input type="text" v-model="model.DESC"/>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
        <el-button icon="el-icon-thumb" type="warning" @click="refuse">拒绝</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认领取</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {BODY:{}},
      stock:{}
    }
  },
  methods:{
    init(model) {
      this.sta={show:true,loading:false}
      this.model =JSON.parse((JSON.stringify(model)));
      this.model.BODY=JSON.parse(this.model.BODY);
      this.getStock(this.model.BODY.ST_ID)
    },
    getStock(id){
      let self=this;
      this.whale.remote.getResult({
        url:"/api/School/MAT/StockApi/get",
        data: {ID:id},
        completed:function (m){
          self.stock=m.DATA;
        }
      })
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/WFL/Act/Next",
        data: {ID:this.model.ID},
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    },
    refuse(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/WFL/Act/Refuse",
        data: {ID:this.model.ID,DESC:this.model.DESC},
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
